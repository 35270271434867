<template>
  <!-- profile css 사용 -->
  <div class="container profile">
    <div class="payment-header" :class="{safe: !isCustom}">
      <template v-if="isCustom">
        <div class="title">잠깐만요!<br>예약 문의 먼저 하셨나요?</div>
        <div class="text">원하시는 일정에<br>진행이 어려울 수 있으니<br>반.드.시 상담 후 구매해주세요!</div>
      </template>
      <template v-else>
        <div class="title ui-weight-6">행사가 끝난 후 지급하는<br> 안전결제로 안심하고 섭외하세요!</div>
        <div class="link">
          헤이비글이 보관 후 지급하는 방식이라
          환불이 쉽고 매우 안전해요! 
          <!-- <span class="ui-btn text-underline" @click="() => $gotoWeb('https://blog.naver.com/wimz0816/221447131334')">더 알아보기</span> -->
        </div>
        <div class="image">
          <icon src="img/payment/safe-payment.png" :width="`${$store.state.app.width}px`" height="14rem" :cover="false" :originUrl="true"/>
        </div>
      </template>
      <div class="ui-border-line" style="height: 10px; margin-top:30px;"></div>
    </div>

    <div class="profile-info">
      <div v-if="$route.params.artistidx > 0">
        <!-- <h4>{{isCustom ? '결제 정보' : '예약 정보'}}</h4> -->
        <h4>예약 정보</h4>
        <div class="info-block">
            <div class="d-flex w-100">
              <span
                class="artist-image ui-bg-img"
                :style="{
                  backgroundImage: `url(${$lib.cdnUrl(artistData.teamImgUrl)})`,
                  backgroundColor: `#eee`
                }"
              />
              <div class="product-detail">
                <div v-if="artistData.teamName" class="product-name ui-ellipsis" style="flex: 1; line-height: 2.8rem">{{artistData.teamName}}</div>
                <div
                  v-if="finalPaymentPrice"
                  class="color-lavender"
                  :style="{
                    fontSize:'2rem',
                    fontWeight: 600,
                    lineHeight: '2.8rem',
                  }"
                  >{{$lib.addComma(finalPaymentPrice)}}원 (부가세 포함)</div>
              </div>
            </div>
        </div>
      <div class="ui-border-line" style="height: 10px; margin-top:30px;"></div>
      </div>

      <div v-if="$route.params.offerUid && !isCustom">
        <h4>의뢰 정보</h4>
        <div class="info-block">
          <div class="request-detail">
            <div class="request-date">{{playDate(offerData.playDate)}}</div>
            <div class="request-team">{{$lib.t_genre(offerData.genre)}} | {{offerData.eventType}}</div>
          </div>
        </div>
        <div class="ui-border-line" style="height: 10px; margin-top:30px;"></div>
      </div>

      <div>
        <h4>결제 수단</h4>
        <div class="info-block">
          <ul class="payment-option-radio" @click="() => paymentIni.form.paymethod = 'wcard'">
            <li>
              <label for="credit">
                <input type="radio" id="credit" class="sr-only" name="payment-option" checked>
                <span class="radio-mark"></span>신용카드
              </label>  
            </li>
          </ul>
          <template v-if="isClient">
            <div class="payment-help">
              <h4 :style="{marginTop: '0', fontSize: '2rem', fontWeight: 500, lineHeight:'2.8rem'}">다른 결제 방법이 필요하세요?</h4>
              <p class="ui-p-indent-1">
                - 회사 규정상 선결제가 어려운 경우<br>
                - 세금계산서 발행이 필요한 경우<br>
                - 해외라 국내 카드 결제가 불가한 경우<br>
                - 오류 또는 시스템 점검으로 결제가 안되는 경우<br><br>
                고객센터로 연락주세요. 빠르게 도와드릴게요!<br>
                <span class="color-lavender text-underline" @click="$gotoWeb('https://pf.kakao.com/_yzxhwC/chat')">지금 바로 연락하기</span>
              </p>
            </div>
          </template>
        </div>
      <div class="ui-border-line" style="height: 10px; margin-top:30px;"></div>
      </div>

      <div v-if="isClient && couponMsg">
        <div class="info-block ui-mt-1 ui-mb-1">
          <ul>
            <li>{{couponMsg}}</li>
          </ul>
        </div>
      </div>
    </div>

    <template v-if="!isCustom">
      <div class="profile-teamDesc">
        <template v-if="isClient">
          <div class="safe-infomation" :class="{open: safeInfomationOpen}">
            <h4 :style="{fontSize: '2.1rem', fontWeight: 500, margin: '1rem 0'}">헤이비글 안전결제 안내 <icon src="img/common/iconfinder-down.png" width="22px" height="2.4rem" :cover="false" @click="() => safeInfomationOpen = !safeInfomationOpen"/></h4>
            <p class="ui-p-indent-2">1. 헤이비글은 의뢰자와 엔터테이너간 No-Show 및<br>대금 미지급에 대한 문제가 발생하지 않도록<br>안전거래(에스크로) 방식을 도입하고 있습니다.</p>
            <p class="ui-p-indent-2">2. 결제 금액은 헤이비글이 안전하게 보관 후<br>등록된 행사일 3일 후 전문가에게 지급합니다.</p>
            <p class="ui-p-indent-2">3. 피해가 발생한 경우 2일 이내에 고객센터로<br>문제 제기 시 대금 지급을 보류합니다.</p>
          </div>
        </template>
        <template v-else>
          <h4 :style="{fontSize: '2.1rem', fontWeight: 500}">대금 지급 안내</h4>
          <p class="ui-p-indent-2">1. 헤이비글은 의뢰자와 엔터테이너간 No-Show 및 대금 미지급에 대한 문제가 발생하지 않도록 안전거래(에스크로) 방식을 도입하고 있습니다.</p>
          <p class="ui-p-indent-2">2. 결제된 금액은 헤이비글의 계좌에 안전하게 보관 후 아래의 시점에 지급됩니다. (중개, 이체 수수료 제외)<br><span class="ui-p-indent d-inline-block">- 의뢰자가 직접 '대금 지급'을 한 경우<br>지정한 계좌로 바로 지급 (영업일 이내)</span><br><span class="ui-p-indent d-inline-block">- 의뢰자가 '대금 지급'을 하지 않았을 경우<br>등록된 행사일 3일 후 자동으로 지급 (영업일 이내)</span></p>
          <p class="ui-p-indent-2">3. 단, 의뢰자가 피해로 인한 문제 제기 시<br>양자 간 협의 완료 전까지 대금 지급은 보류됩니다.</p>
        </template>
      </div>
      <div class="ui-border-line" style="height: 10px;"></div>
    </template>

    <div class="profile-teamDesc">
      <template v-if="isClient">
        <h4 :style="{fontSize: '2.1rem', fontWeight: 500, marginTop: '1rem'}">환불 및 취소 안내</h4>
        <template v-if="isCustom && cusFlag === 2">
          <p class="ui-p-indent-2">1. 환불 및 취소는 2주 전까지만 가능하며<br>시점에 따라 위약금이 발생합니다.
          <span class="ui-p-indent d-inline-block">행사 30일 전까지 : 100% 환불</span><br>
          <span class="ui-p-indent d-inline-block">행사 29일 ~ 14일 전 취소 시 : 50% 환불</span><br>
          <span class="ui-p-indent d-inline-block">행사 13일 ~ 당일 : 환불 불가</span></p>
          <p class="ui-p-indent-2">2. 환불 문의<br>
          <span class="ui-p-indent d-inline-block">고객센터 : 02-3144-1806, 카카오톡@헤이비글</span><br>
          <span class="ui-p-indent d-inline-block">상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)</span></p>
        </template>
        <template v-else-if="isCustom && cusFlag === 1">
          <p class="ui-p-indent-2">1. 환불 안내<br>
          <span class="ui-p-indent d-inline-block">체크인일자 3일전 취소 시 공제없이 100% 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 2일전 취소 시 30%공제 후 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 1일전 취소 시 50%공제 후 환불</span><br>
          <span class="ui-p-indent d-inline-block">체크인일자 당일/No-Show 취소 및 환불불가</span><br>
          <span class="ui-p-indent d-inline-block">주말 및 공휴일 취소 시 익영업일에 취소한 것으로 인정</span></p>
          <p class="ui-p-indent-2">2. 예약 및 취소 문의<br>
          <span class="ui-p-indent d-inline-block">고객센터 : 02-3144-1806, 카카오톡@헤이비글</span><br>
          <span class="ui-p-indent d-inline-block">상담시간 : 평일 10시~18시 (주말 및 공휴일 제외, 점심 13시~14시 제외)</span></p>
        </template>
        <template v-else>
          <p class="ui-p-indent-1">결제/섭외를 취소하는 경우 아래의 규정을 따릅니다.<br>
          <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 위약금 50%</span><br>
          <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 및 환불 불가</span></p>
          <br>
          <p class="ui-p-indent-1">엔터테이너가 취소 시 아래의 규정을 따릅니다.<br>
          <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 결제금액의 50%를 배상</span><br>
          <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 불가, 300%를 배상</span><br>
          <span class="ui-p-indent color-lavender text-underline" @click="$router.push('/etc/refund-policy')">자세히 알아보기</span></p>
        </template>
      </template>
      <template v-else>
        <h4 :style="{fontSize: '2.1rem', fontWeight: 500}">취소 보상금 및 손해배상 안내</h4>
        <p class="ui-p-indent-1">결제/섭외를 취소하는 경우 아래의 규정을 따릅니다.<br>
        <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 위약금 50%</span><br>
        <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 및 환불 불가</span></p>
        <br>
        <p class="ui-p-indent-1">엔터테이너가 취소 시 아래의 규정을 따릅니다.<br>
        <span class="ui-p-indent d-inline-block">- 13~7일 전 취소 시 : 결제금액의 50%를 배상</span><br>
        <span class="ui-p-indent d-inline-block">- 6일 전~당일 취소 시 : 취소 불가, 300%를 배상</span><br>
        <span class="ui-p-indent color-lavender text-underline" @click="$router.push('/etc/refund-policy')">자세히 알아보기</span></p>
      </template>
    </div>
    <div class="ui-border-line" style="height: 10px"></div>

    <template v-if="offerData && /(결혼식|주례없는 결혼식)/.test(offerData.eventType)">
      <ul class="overflow-hidden" @click="() => $gotoWeb('https://blog.naver.com/wimz0816/222641646144')">
        <li
          class="ui-weight-5 float-left"
          :style="{
            color: '#000',
            lineHeight: '85px',
            height: '85px',
            fontSize: '2.1rem',
          }"
        >
          예약 후 리뷰 쓰면 최대 5만원 지급
        </li>
        <li class="float-right">
          <icon src="img/common/right-arrow-gray.png" width="2rem" height="8.4rem" :cover="false" />
        </li>
      </ul>
      <div class="ui-border-line" style="height: 10px"></div>
    </template>

    <left-check-box
      class="ui-mt-1 ui-mb"
      text="위 구매조건 확인 및 결제 진행 동의"
      :value="agreeCheck"
      @click="result => {
        agreeCheck = result.checked
        result.update()
      }"
      />
    <div class="ui-border-line" style="height: 10px;margin-top: 0.7rem;"></div>

    <!-- <footer-box
      :submit-text="!isCustom ? `${$lib.addComma(finalPaymentPrice) || '-'}원 결제하기 >` : '결제하기 >'"
      :submit-cb="() => paymentIniRun()"
      :submit-disabled="!agreeCheck"
    > -->
		<footer-box
      :submit-text="`${$lib.addComma(finalPaymentPrice) || ''}원 결제하기 >`"
      :submit-cb="() => paymentIniRun()"
      :submit-disabled="!agreeCheck"
    >

    </footer-box>

    <form ref="ini" method="post" :action="paymentIni.actionUrl + '/' + paymentIni.form.paymethod + '/'" :accept-charset="paymentIni.acceptCharset" hidden>
      <input type="hidden" name="inipaymobile_type" :value="paymentIni.form.inipaymobile_type" />
      <input type="hidden" name="paymethod" :value="paymentIni.form.paymethod" />
      <input type="hidden" name="P_MID" :value="paymentIni.form.P_MID" />
      <input type="hidden" name="P_OID" :value="paymentIni.form.P_OID" />
      <input type="hidden" name="P_AMT" :value="paymentIni.form.P_AMT" />
      <input type="hidden" name="P_UNAME" :value="paymentIni.form.P_UNAME" />
      <input type="hidden" name="P_MNAME" :value="paymentIni.form.P_MNAME" />
      <input type="hidden" name="P_NOTI" :value="paymentIni.form.P_NOTI" />
      <input type="hidden" name="P_GOODS" :value="paymentIni.form.P_GOODS" />
      <input type="hidden" name="P_MOBILE" :value="paymentIni.form.P_MOBILE" />
      <input type="hidden" name="P_EMAIL" :value="paymentIni.form.P_EMAIL" />
      <input type="hidden" name="P_VBANK_DT" v-if="paymentIni.form.P_VBANK_DT" :value="paymentIni.form.P_VBANK_DT" />
      <input type="hidden" name="P_VBANK_TM" v-if="paymentIni.form.P_VBANK_TM" :value="paymentIni.form.P_VBANK_TM" />
      <input type="hidden" name="P_RESERVED" :value="paymentIni.form.P_RESERVED" />
      <input type="hidden" name="P_NEXT_URL" :value="paymentIni.form.P_NEXT_URL" />
      <input type="hidden" name="P_NOTI_URL" v-if="paymentIni.form.P_NOTI_URL" :value="paymentIni.form.P_NOTI_URL" />
      <input type="hidden" name="P_QUOTABASE" v-if="paymentIni.form.P_QUOTABASE" :value="paymentIni.form.P_QUOTABASE" />
    </form>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'
import LeftCheckBox from '@/components/common/LeftCheckBox'
import {
  isEarlybirdEventArtist,
  isDiscountEventCustomArtist
} from '@/lib/artist.js'

export default {
  name: 'Payment',
  components: {
    Icon,
    FooterBox,
    LeftCheckBox,
  },
  data() {
    const API_URL = this.$lib.API_URL

    return {
      API_URL,
      offerData: {},
      artistData: {},
      artistOption: [],
      extProd: [],
      agreeCheck: false,
      paymentIni: {
        actionUrl: 'https://mobile.inicis.com/smart',
        acceptCharset: 'UTF-8',
        form: {
          inipaymobile_type: 'web',
          paymethod: 'wcard',
          P_MID: '',
          P_OID: '',
          P_AMT: '0',
          P_UNAME: '',
          P_MNAME: 'HeyBeagle',
          P_NOTI: '',
          P_GOODS: '',
          P_MOBILE: '',
          P_EMAIL: '',
          P_VBANK_DT: '', // 입금기한 (년월일) ex: 20181230
          P_VBANK_TM: '', // 입금기한 (시분)
          // 결제옵션 파라미터
          P_RESERVED: 'twotrs_isp=Y&block_isp=Y&twotrs_isp_noti=N&ismart_use_sign=Y&vbank_receipt=N&bank_receipt=N&apprun_check=Y&useescrow=N&app_scheme=iamporthb://',
          P_NEXT_URL: `${API_URL}/web/payment`,
          P_NOTI_URL: '', // 신용카드일때만 `${API_URL}/web/payment2`
          P_QUOTABASE: '', // 신용카드일때만 '01:03:06:09:12'
        },
      },
      safeInfomationOpen: false,
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    offerUid() {
      return this.$route.params.offerUid
    },
    isCustom() {
      return this.offerUid === 'custom'
    },
    isOptionItem() {
      return this.artistData.optionData?.length >= 1;
    },
    isExtProdItem() {
      return this.artistData.extProdData?.length >= 1;
    },
    cusFlag() {
      return this.$config.constant.cusFlag.indexOf(this.artistData.cusFlag)
    },
    playDate() {
      return (playDate) => {
        let date = moment(Number(playDate) * 1000)
        // let noon = '오전'
        // if (date.format('H') >= 12) noon = '오후'
        // return date.format(`YYYY.MM.DD / ${noon} hh시 mm분`)
        return date.format(`YYYY.MM.DD`)
      }
    },
    priceFeesPer() {
      return Number(this.$store.state.user.priceFeesPer)
    },
    priceFees() {
      return this.$lib.floor(this.afterDiscountPaymentPrice * this.priceFeesPer / 100)
    },
    paymentPrice() {
      if (this.isCustom) {
        return Number(this.artistData.cusPriceStart)
      }
      return Number(this.artistData.proPrice)
    },
    afterDiscountPaymentPrice() {
      return this.$lib.floor(this.paymentPrice - this.$lib.floor(this.paymentPrice * this.artistSalePer / 100), 2);
    },
    afterFeesPaymentPrice() {
      if (this.isCustom) {
        return this.afterDiscountPaymentPrice
      }
      return this.afterDiscountPaymentPrice + this.priceFees
    },
    finalPaymentPrice() {
      let price = 0;
      price = this.afterFeesPaymentPrice - this.couponDiscountPrice

      if(this.isOptionItem) {
        // 옵션 있을 때 정한 옵션 값들 더하기
        price = (this.artistOption.reduce((p,c,i)=>p+((this.afterFeesPaymentPrice + c.price) * c.count),0));
      } else if (this.isCustom) {
        // 옵션 없을 때 상품 가격
        price = this.afterFeesPaymentPrice
      }

      if(this.isExtProdItem && this.isCustom) {
        // 추가상품 있을 때 정한 추가상품 값들 더하기
        price = price + (this.extProd.reduce((p,c,i)=>p+(c.price * c.count),0));
      }
      
      // 별도 작업비 더하기
      if(this.artistData.addWorkPrice && this.isCustom) {
        price = price + Number(this.artistData.addWorkPrice);
      }
      if(this.artistData.includeVatFlag == 'n' && this.isCustom && this.artistData.priceType) {
        price = price + price*0.1;
      }
      return Math.floor(price);
    },
    remainDays() {
      const now = moment(moment(new Date()).format('YYYY-MM-DD'))
      const end = moment(moment(Number(this.offerData.playDate) * 1000).format('YYYY-MM-DD'))
      return end.diff(now, 'days')
    },
    billData() {
      return this.$store.state.billData || {}
    },
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
    couponData() {
      return this.$store.state.user.couponData || []
    },
    hasWelcomeCoupon() {
      return this.couponData.filter(row => row.category == 1).length > 0
    },
    couponDiscountPrice() {
      if (this.hasWelcomeCoupon && this.isClient && !this.isCustom) {
        if (this.paymentPrice >= 300000) return 15000
        else if (this.paymentPrice >= 200000) return 10000
        else if (this.paymentPrice >= 100000) return 5000
      }
      return 0
    },
    couponMsg() {
      if (this.hasWelcomeCoupon && this.isClient && this.couponDiscountPrice > 0) {
        if (this.paymentPrice >= 300000) return '웰컴 쿠폰 - 30만원 이상 시 15,000원 할인!'
        else if (this.paymentPrice >= 200000) return '웰컴 쿠폰 - 20만원 이상 시 10,000원 할인!'
        else if (this.paymentPrice >= 100000) return '웰컴 쿠폰 - 10만원 이상 시 5,000원 할인!'
      }
      return ''
    },
    artistSalePer() {
      const salePer = Number(this.artistData?.salePer) || 0
      const useSalePer = [
        () => this.isEarlybirdEventArtist,
        () => this.isCustom && this.isDiscountEventCustomArtist
      ].some((test) => test())

      if (useSalePer) {
        return salePer
      }

      return 0
    },
    isEarlybirdEventArtist() {
      return isEarlybirdEventArtist(this.artistData, this.offerData)
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.artistData)
    },
  },
  created() {
    const { offerUid, artistidx } = this.$route.params

    // 전문가는 바로 결과화면으로 이동
    if (this.userData.category === 'artist') {
      this.$router.replace(`/payment/${offerUid}/${artistidx}/result`)
      return
    }

    if (offerUid && !this.isCustom) {
      this.getOffer()
    }

    if (artistidx == 0) {
      this.$toast('전문가 정보를 불러오는데 실패하였습니다.')
    } else {
			let artistData = null

			if(this.offerUid !== 'custom') {
				artistData = sessionStorage.getItem(`artistData:${this.$route.params.artistidx}:${this.offerUid}`)
			}else {
				artistData = sessionStorage.getItem(`artistData:${this.$route.params.artistidx}`)
			}
			
			let artistOption = localStorage.getItem(`artistOption:${this.$route.params.artistidx}`);
      let extProd = localStorage.getItem(`extProd:${artistidx}`);
      
      if (artistData) {
        this.artistData = JSON.parse(artistData)
      } else {        
        this.getProfile()
      }
      if(artistOption) {
        this.artistOption = JSON.parse(artistOption);
      }

      if(extProd) {
        this.extProd = JSON.parse(extProd);
      }
    }
    window.injectPaymenResult = this.injectPaymenResult
  },
  beforeDestroy() {
    window.injectPaymenResult = () => false
  },
  methods: {
    getOffer() {
      if (this.isCustom) return

      const req = {
        method: 'get',
        url: `/client/offer/${this.offerUid}`,
      }

      this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.offerData = data.response.offerData || {}

            this.paymentIni.form.paymethod = 'wcard'
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    getProfile() {
      const { offerUid, artistidx } = this.$route.params
      const req = {
        method: 'get',
        url: `/artist/profile/${artistidx}`,
      }

      if (offerUid && !this.isCustom) {
        req.method = 'post'
        req.data = {
          offerUid,
        }
        if (this.isClient) req.data.offerRead = 'y'
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            this.artistData = data.response.artistData || {}
            // sessionStorage.setItem(`artistData:${this.artistData.artistidx}:${this.offerUid}`, JSON.stringify(this.artistData))
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    gotoProfile() {
      const { offerUid, artistidx } = this.$route.params
      const historyStack = this.$store.state.history.stack
      const profileUrl = !this.isCustom
        ? `/client/my-offer/${offerUid}/${artistidx}`
        : `/artist/profile/${artistidx}`

      if (historyStack.indexOf(profileUrl) === -1) {
        this.$router.push(profileUrl)
      } else {
        this.$router.back()
      }
    },
    gotoOffer() {
      this.$router.push(`/client/my-offer/${this.offerUid}`)
    },
    // FIXME: 안쓰는 소스
    // paymentRun() {
    //   if (process.env.NODE_ENV === 'development') {
    //     this.$toast(`${this.artistData.artistidx}|${this.offerUid}|${Number(this.artistData.proPrice) + this.priceFees}|${this.userData.userName}|${this.artistData.userNickName || 'x'}`)
    //   }

    //   this.$runToApp({
    //     appCb: () => location.href = `hbscheme://paymenRun?params=${this.artistData.artistidx}|${this.offerUid}|${Number(this.artistData.proPrice) + this.priceFees}|${this.userData.userName}|${this.artistData.userNickName || 'x'}`,
    //     webCb: () => {
    //       // this.$router.push(`/payment/${this.offerUid}/${this.$route.params.artistidx}/result`)
    //     },
    //   })
    // },
    paymentIniRun() {
      const order_form = this.$refs.ini
      const { offerUid, artistidx } = this.$route.params

      this.paymentIni.form.P_MID = this.billData.iniMid

      if (!this.isCustom) {
        this.paymentIni.acceptCharset = 'EUC-KR'
        this.paymentIni.form.P_GOODS = offerUid
        this.paymentIni.form.P_AMT = Math.floor(this.finalPaymentPrice || '0').toString()
        this.paymentIni.form.P_EMAIL = this.userData.email
        this.paymentIni.form.P_UNAME = this.userData.userName
        this.paymentIni.form.P_MOBILE = this.userData.phone
        this.paymentIni.form.P_OID = 'heybeagle_' + offerUid + '_' + new Date().getTime()
        this.paymentIni.form.P_NOTI = `${offerUid}|${artistidx}|${this.userData.useridx}`
        this.paymentIni.form.P_NEXT_URL = `${this.API_URL}/web/payment`

        this.$historyStack.set([
          `/client/my-offer`,
          `/client/my-offer/${offerUid}/${artistidx}`,
          `/payment/${offerUid}/${artistidx}/result?p_msg=결제가 취소되었습니다.`,
        ])
      } else {
        let artistOptionString = this.artistOption.map((item) => {
         return `${item.label}(${item.count})`;
        }).join(', ');

        let extProdString = this.extProd.map((item) => {
         return `${item.label}(${item.count})`;
        }).join(', ');


        this.paymentIni.acceptCharset = 'EUC-KR'
        this.paymentIni.form.P_GOODS = `${this.artistData.teamName} [${artistidx}]`
        this.paymentIni.form.P_AMT = Math.floor(this.finalPaymentPrice || '0').toString()
        this.paymentIni.form.P_EMAIL = this.userData.email
        this.paymentIni.form.P_UNAME = this.userData.userName
        this.paymentIni.form.P_MOBILE = this.userData.phone
        this.paymentIni.form.P_OID = 'heybeagle_' + this.userData.useridx + '_' + new Date().getTime()
        this.paymentIni.form.P_NOTI = `${artistidx}|${this.userData.useridx}`
        this.paymentIni.form.P_NEXT_URL = `${this.API_URL}/web/payment4` + (artistOptionString ? `?option_info=${artistOptionString}&` : '?') + (extProdString ? `ext_prod_info=${extProdString}` : '');

        this.$historyStack.set([
          `/artist/profile/${artistidx}`,
          `/payment/${offerUid}/${artistidx}/result?p_msg=결제가 취소되었습니다.`,
        ])
      }

      if (this.couponDiscountPrice) {
        sessionStorage.setItem(`couponDiscountPrice:${this.offerUid}`, this.couponDiscountPrice.toString())
      }

      // 신용카드일때
      this.paymentIni.form.P_NOTI_URL = ''
      this.paymentIni.form.P_QUOTABASE = '01:03:06:09:12'
      this.paymentIni.form.P_VBANK_DT = ''
      this.paymentIni.form.P_VBANK_TM = ''

      this.$nextTick().then(() => {
        localStorage.setItem('TryPayment', Date.now().toString())
        order_form.submit()
      })
    },
    // 정기결제 테스트
    paymentIni2Run() {
      const order_form = this.$refs.ini2
      const { offerUid, artistidx } = this.$route.params

      this.paymentIni2.form.buyername = this.userData.userName
      this.paymentIni2.form.price = '19800' // Math.floor(Number(this.artistData.proPrice) + this.priceFees || '0').toString()
      this.paymentIni2.form.p_noti = `${offerUid}|${artistidx}|${this.userData.useridx}`

      this.$nextTick().then(() => {
        localStorage.setItem('TryPayment', Date.now().toString())
        order_form.submit()
      })
    },
    injectPaymenResult() {
      const { offerUid, artistidx } = this.$route.params
      this.$router.replace(`/payment/${offerUid}/${artistidx}/result`)
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.payment-header {
  background-color: $color-deepLavender;
  color: #fff;
  text-align: center;
  margin: 0 -3rem;
  overflow: hidden;
  .title {
    margin: 3rem 0;
    font-size: 3.2rem;
    font-weight: 600;
  }
  .text {
    margin: 3rem 0;
    font-size: 2rem;
    line-height: 1.5;
  }
  .image {
    div {
      margin: 0 auto;
    }
  }

  &.safe {
    color: #000;
    background-color: #fff;
    text-align: left;
    .title {
      margin: 4rem 3rem 1.8rem;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 3.4rem;
    }
    .text {
      margin: 0 3rem 0.5rem;
      font-size: 3rem;
      font-weight: 400;
      line-height: 1.5;
    }
    .link {
      margin: 0 3rem 0.5rem;
      font-size: 2rem;
      color: #6f7785;
      line-height: 2.8rem;
    }
    .image {
      margin-top: 3.3rem;
      div {
        margin: 0 auto;
      }
    }
  }
}
.artist-image {
  display: block;
  width: 6.8rem;
  height: 6.8rem;
  border-radius: 0.94rem;
  margin-right: 1.8rem;
}
#page h4 {
  font-size: 2.4rem;
  color: #000;
  line-height: 3.4rem;
  font-weight: 600;
  margin: 3rem 0 2rem;
}
#page > .container.profile {
  .profile-info .info-block ul li {
   line-height: 1.8rem;
   min-height: 6rem;
   width: auto;
   color: #6f7785;
  }
    &:first-child {
      width: auto;
      color: #6f7785;
    }
    &:nth-child(2) {
      font-weight: 500;
    }

  .safe-infomation {
    h4 {
      .icon {
        float: right;
        opacity: 0.2;
        cursor: pointer;
      }
    }
    p {
      display: none;
    }
    &.open {
      .icon {
        transform: rotate(180deg);
      }
      p {
        display: block;
      }
    }
  }

  .profile-info {
    .info-block {
      .d-flex {
        align-items: center;
      }
      .product-detail {
        .product-name {
          margin-bottom: 0.8rem;
          color: #000;
          font-size: 2rem;
          font-weight: 600;
          line-height: 2.8rem;
        }
      }

      .request-detail {
        .request-date {
          margin-bottom: 0.8rem;
          color: #000;
          font-size: 2rem;
          font-weight: 500;
          line-height: 2.8rem;
        }
        .request-team {
          font-size: 2rem;
          font-weight: 500;
          line-height: 2.8rem;
        }
      }

      .payment-option-radio {
        margin-bottom: 2.4rem;
        li{
          min-height: auto;
          padding: 0;
          label {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: #000;
            font-size: 2rem;
            line-height: 2.8rem;
            font-weight: 500;
          }
          input {
            -webkit-appearance:none;

            &:checked {
              & + .radio-mark {
                position: relative;
                border: 1px solid #ab54bb;
                &::after {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                  background-color: #ab54bb;
                  transform: translate(-50%, -50%);
                  content: '';
                }
              }
            }
          }
          .radio-mark {
            display: inline-block;
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 1.4rem;
            border-radius: 50%;
          }
        }
      }

      .payment-help {
        padding: 2.4rem;
        border: 0.1rem solid #e2d8f9;
        border-radius: 7px;
        font-size: 1.8rem;
        line-height: 2.5rem;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
